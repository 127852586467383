 .main-clinic-profile {
   font-family: "Poppins" !important;
   margin: 0 5%;
   /* Default margin for smaller screens */

   @media (min-width: 1080px) and (max-width: 2000px) {
     margin: 0 5%;
     /* Margin for screens between 1080px and 2000px wide */
   }

   @media (min-width: 2000px) and (max-width: 2500px) {
     margin: 0 12%;
     /* Margin for screens between 2000px and 2500px wide */
   }

   @media (min-width: 2500px) {
     margin: 0 20%;
     /* Margin for screens wider than 2500px */
   }
 }

 .select-filter {
   border-radius: 8px !important;
 }

 body .main-clinic-profile-small {
   font-family: "Poppins" !important;
   margin: 0px 10px;
 }

 .leaflet-bottom.leaflet-right {
   display: none;
   background-color: white;
 }

 .search-page {
   padding: 0px 40px;
   background-color: #ffffff;
   min-height: 100vh;
 }

 .clinicCard {
   background-color: #ffffff;
   width: 100%;
   padding: 35px;
   border-radius: 4px;
   line-height: 4;
 }

 .clinicCard:hover {
   /* background-color: #c9d0eb; */
   cursor: pointer;
 }

 .boldText {
   font-weight: bold;
 }

 .distanceCard {
   font-size: 10px;
   border-radius: 40px;
   margin-left: 15px;
   background: #efefef;
   padding: 8px 8px;
   display: inline;
   align-items: center;
 }

 .mg0 {
   margin: 0px;
 }

 .mg1 {
   margin: 1px;
 }

 .mg2 {
   margin: 2px;
 }

 .mg3 {
   margin: 3px;
 }

 .mg4 {
   margin: 4px;
 }

 .mg5 {
   margin: 5px;
 }

 .mg6 {
   margin: 6px;
 }

 .serviceDentalCard {
   font-size: 14px;
   color: #9176DC;
 }

 .cardInfoClinic {
   color: #939393;
   font-size: 16px;
 }

 .popUpMapCard {
   background-color: #5D3B9C !important;
   color: white !important;
   font-family: "Poppins" !important;
 }

 .leaflet-popup-content-wrapper,
 .leaflet-popup-tip {
   background-color: #5D3B9C !important;
   color: white !important;
 }

 .leaflet-popup-content-wrapper .ant-card-head-title {
   color: white !important;
   font-size: 18px;
   font-weight: bold;
 }

 .leaflet-popup-close-button {
   color: white !important;
   top: 5px !important;
   right: 5px !important;
 }

 .pointerClick {
   cursor: pointer;
 }

 .ant-drawer-open {
   z-index: 10001;
 }

 body:has(.main-clinic-profile) {
   background-color: #fff !important;
 }

 .main-clinic-profile:has(.clinic-info-container) {
   background-color: #fff !important;
 }

 .main-clinic-profile {
   background-color: #FFF !important;
 }

 .main-clinic-profile-new {
   background-color: #fafafa !important;
 }

 .container-clinic {
   margin: 30px 0px;
 }

 .container-clinic-page .head-profile {
   background-color: #f6f6fd;
   margin: 30px 0px;
 }

 .head-profile {
   width: 100%;
   border-radius: 4px;
   padding: 8px 30px;
   gap: 8px;
   background-color: #f6f5fd;
 }

 .clinic-infoCard-logo {
   background-color: white;
   border-radius: 50%;
   width: 150px;
   height: 150px;
   position: absolute;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .align-center-flex {
   align-items: center;
 }

 .clinic-card-name-container {
   min-height: 100%;
   display: flex;
   align-items: center;
   width: 100%;
 }

 .clinic-infoCard-name {
   font-size: 40px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   text-align: left !important;
   padding-left: 50px;
   background-color: white;
   width: 100% !important;
   height: 100% !important;
   margin-left: 130px;
   border-radius: 0px 8px 8px 0px;
   margin-left: 110px;
   text-align: center;
   font-weight: bold;
 }

 .clinic-infoCard-service {
   font-weight: 300 !important;
   color: #9176DC;
   font-size: 14px;
   max-width: 85%;
   margin-top: 10px;
 }

 .clinic-infoCard-rating {
  line-height: 3;
   background-color: white;
   border-radius: 8px;
   height: 100%;
   display: contents;
   width: 100%;
   max-height: 120px;
 }

 .clinic-contact-info {
   padding-left: 50px;
 }

 .card-contact-info {
   font-size: 11px;
   flex-direction: column;
   line-height: 2.6;
 }

 .clinic-infoCard-info {
   background-color: white;
   border-radius: 8px;
   max-height: 120px;
   height: 100%;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   color: black !important;
   margin-left: 15px;
 }

 .clinic-infoCard {
   width: 100%;
   background-color: white !important;
   line-height: 35px;
   padding: 0px;
   margin-bottom: 25px;
   border-radius: 32px;
 }

 .appointment-clinic-color-bg {
   background: #f7f7f7;
 }

 .clinic-profile-images-new {
   margin-top: -15px;
 }

 .provider-clinic {
   cursor: pointer;
   border-radius: 4px;
   background-color: #f6f5fd;
   margin-bottom: 15px;
 }

 .footer-1 {
   background-color: #301f56;
   color: #fff;
 }

 .footer-1 {
   font-family: "Poppins" !important;
   padding: 3% 5%;
   /* Default margin for smaller screens */

   @media (min-width: 1080px) and (max-width: 2000px) {
     padding: 3% 5%;
     /* Margin for screens between 1080px and 2000px wide */
   }

   @media (min-width: 2000px) and (max-width: 2500px) {
     padding: 3% 12%;
     /* Margin for screens between 2000px and 2500px wide */
   }

   @media (min-width: 2500px) {
     padding: 3% 20%;
     /* Margin for screens wider than 2500px */
   }
 }

 .footer-2 {
   background-color: #11062a;
   color: #fff;
 }

 .footer-2 {
   font-family: "Poppins" !important;
   padding: 1% 5%;
   /* Default margin for smaller screens */

   @media (min-width: 1080px) and (max-width: 2000px) {
     padding: 1% 5%;
     /* Margin for screens between 1080px and 2000px wide */
   }

   @media (min-width: 2000px) and (max-width: 2500px) {
     padding: 1% 12%;
     /* Margin for screens between 2000px and 2500px wide */
   }

   @media (min-width: 2500px) {
     padding: 1% 20%;
     /* Margin for screens wider than 2500px */
   }
 }

 .footer-1-title {
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 25px;
 }

 .footer-1-items {
   line-height: 25px;
   margin-top: 25px;
   font-size: 12px;
 }

 .footer-signup {
   margin-left: 10px;
 }

 .profile-clinic-section-title {
   font-weight: bold;
   font-size: 16px;
 }

 .profile-clinic-section-subtitle {
   color: #838383;
   font-size: 12px;
 }

 .ant-carousel .slick-slide {
   text-align: center;
   min-height: 160px;
   max-width: 50px;
   line-height: 160px;
   background: #ffff;
   overflow: hidden;
   border-radius: 8px;
 }

 .ant-carousel .slick-slide h3 {
   color: #fff;
 }

 .card-membership {
   margin-top: 20px;
   background-color: #fff;
   min-width: 49%;
   border-radius: 8px;
   border: 0px;
   padding: 30px;
 }

 .card-membership-modal {
   background-color: #fff;
   min-width: 49%;
   border-radius: 8px;
   border: 0px;
 }

 .select-type-appointment {
   width: 40%;
 }

 .book-clinic-button-card {
   background-color: transparent;
   border: 2px solid #6b43b5;
   color: #6b43b5;
   padding: 0px 8px;
   font-size: 12px;
   border-radius: 5000px;
 }

 .book-clinic-button {
   background-color: transparent;
   border: 1px solid #6b43b5;
   color: #6b43b5;
   padding: 5px 30px;
   border-radius: 5000px;
 }

 .text-header-home {
   font-size: 45px;
   font-weight: bold;
   line-height: 70px;
 }

 .text-header-home span {
   color: #6b43b5;
 }

 .search-box-home {
   width: 100%;
   border-radius: 4px;
   background-color: #fff;
   margin-bottom: 20px;
   margin-top: -25px;
 }

 .search-box-clinic-list {
   width: 100%;
   border-radius: 4px;
   background-color: #fff;
   margin-bottom: 20px;
   margin-top: 25px;
   box-shadow: rgba(0, 60, 178, 0.16) 0px 2px 5px;
 }

 .input-search-box {
   border: 0px;
   min-width: 100%;
   margin-right: 5px;
   display: flex;
   flex-direction: column;
   justify-content: end;
   font-size: 14px;
   align-self: center;
   padding-top: 2px;
 }

 .input-search-box:hover {
   border: 0px !important;
   outline: 0px !important;
 }

 .input-search-box::placeholder {
   color: #dbdbdb;
 }

 .input-search-box:focus {
   border: 0px !important;
   outline: 0px !important;
   -webkit-box-shadow: 0px !important;
   box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
 }

 .input-search-box .ant-select-selection {
   border: 0px;
   margin-right: 5px;
 }

 .input-search-box .ant-select-selection:hover {
   border: 0px !important;
   outline: 0px !important;
 }

 .input-search-box .ant-select-selection:focus {
   border: 0px !important;
   outline: 0px !important;
   -webkit-box-shadow: 0px !important;
   box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
 }

 .search-button-box {
   background-color: #6b43b5;
   border: 0px;
   color: white;
   width: 100%;
   height: 62px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 4px;
   cursor: pointer;
 }

 .search-box-search {
   width: 100%;
 }

 .select-filter {
   min-width: 120px;
   background: #f1f1f1;
   border: 0px;
   color: #7a7a7a !important;
 }

 .clinic-info-container {
   min-height: 200px;
   z-index: +53;
   position: relative;
 }

 .leaflet-container {
   border-radius: 4px;
 }

 .membership-fixed {
   margin-top: 25px;
   position: sticky;
   z-index: 555;
   top: 0;
   right: 80px;
   min-width: 37.89%;
 }

 .membership-fixed-bottom {
   position: absolute;
   z-index: 555;
   width: 99%;
 }

 .card-logo-office {
   border-radius: 50%;
 }

 .card-container-wrapper {
   display: flex;
   text-align: center;
   justify-content: space-between;
 }

 #custom-scrollbar-x {
   margin: -1px;
   overflow: auto;
 }


 #custom-scrollbar-x::-webkit-scrollbar {
   height: 8px;
 }

 #custom-scrollbar-x::-webkit-scrollbar-thumb {
   background-color: #aaa;
   border-radius: 3px;
 }

 #custom-scrollbar-x::-webkit-scrollbar-thumb:hover {
   background-color: #aaa;
 }

 #custom-scrollbar {
   width: 300px;
   margin: -1px;
   overflow: auto;
 }

 #custom-scrollbar::-webkit-scrollbar {
   width: 4px;
 }

 #custom-scrollbar::-webkit-scrollbar-thumb {
   background-color: #aaa;
   border-radius: 3px;
 }

 #custom-scrollbar::-webkit-scrollbar-thumb:hover {
   background-color: #aaa;
 }

 .image-container {
   padding: 20px;
 }

 .gallery-images {
   max-width: 100%;
   height: auto;
 }

 .gallary-container-card {
   width: 100%;
 }

 .gallary-container-card-mobile-size {
   max-width: 150px;
 }

 .gallary-container-card-profile {
   max-width: 54%;
   height: 100px;
 }

 .select-filter0 .ant-select-selector {
   border: 0px !important;
 }

 .select-filter0 .ant-select-selection {
   border: 0px !important;
   display: flex;
   flex-direction: column;
   justify-content: end;
   font-size: 14px;
 }

 .fullHeight {
   height: 100%;
 }

 .select-filter .ant-select-selection {
   background: #FFF;
   border: 0px;
   color: #7a7a7a;
   border-radius: 8px;
 }

 .select-filter-type.ant-select-open {
   background-color: white !important;
   border: 0px solid #6B43B5 !important;
   color: #7a7a7a;
   border-radius: 50px !important;

 }

 .select-filter-type .ant-select-selection {
   background-color: white !important;
   border: 1px solid #6B43B5 !important;
   color: #7a7a7a;
   border-radius: 50px !important;
   min-height: 35px;
   display: flex;
   align-items: center;
   font-size: 12px;
 }

 .ant-rate {
   color: #9176dc;
 }

 .ant-rate-star {
   margin: 2px;
 }

 .clinic-photo {
   width: 50%;
   height: 50%;
 }



 .review-card {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 20px;
   text-align: center;
   border-radius: 8px;
   line-height: 5 !important;
   margin: 0 10px !important;
   transition: transform 0.3s ease !important;

   &:hover {
     transform: scale(1.05) !important;
   }
 }

 .review-content {
   margin-bottom: 16px;
   padding-top: 30px;
 }

 .review-content-center {
   font-size: 25px;
   padding-top: 30px;
 }

 .provider-name-center {
   font-size: 22px;
 }

 .review-author {
   font-weight: bold;
 }

 .review-rating {
   color: #1890ff;
 }

 .carousel-buttons {
   display: flex;
   align-items: center;
 }

 .carousel-button {
   background-color: #007bff;
   color: #fff;
   border: none;
   font-size: 24px;
   cursor: pointer;
   padding: 8px 16px;
   margin: 8px;
   border-radius: 4px;
   transition: background-color 0.3s;
 }

 .carousel-button:hover {
   background-color: #0056b3;
 }

 .prev-button {
   margin-right: auto;
 }

 .next-button {
   margin-left: auto;
 }

 .alice-carousel {
   position: relative;
 }

 .alice-carousel__prev-btn,
 .alice-carousel__next-btn {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   background: rgba(255, 255, 255, 0.5);
   padding: 10px;
   border-radius: 50%;
   cursor: pointer;
 }

 .alice-carousel__prev-btn {
   left: 0px;
   text-align: inherit;
   width: auto !important;
   background-color: white;
   padding-top: 80px;
 }

 .alice-carousel__next-btn {
   right: 0;
   text-align: end;
   padding-top: 80px;
   background-color: white;
   width: auto !important;
 }

 .alice-carousel__prev-btn-item {
   border: 1.5px solid #292d32;
   opacity: 0.5;
   padding: 2px 10px;
   border-radius: 8px;
 }

 .alice-carousel__next-btn-item {
   border: 1.5px solid #292d32;
   opacity: 0.5;
   padding: 2px 10px;
   border-radius: 8px;
 }

 .search-map-container .leaflet-container {
   min-height: 100vh !important;
   margin-top: 10px;
 }

 .ant-drawer-body {
   height: 100%;
 }

 .white-color {
   color: #fff !important;
 }

 .placeholder-color {
   color: rgba(0, 0, 0, 0.25);
 }

 .clinic-profile-images {
   max-width: -webkit-fill-available;
   width: 100%;
   height: 300px;
   border-radius: 8px;
   overflow: hidden;
   object-fit: cover;
 }

 .clinic-footer {
   z-index: +5555;
   position: relative;
 }

 .rating-clinic-container {
   padding-left: 60px;
 }

 .spin-loading {
   margin-top: 5%;
 }

 .clinic-cards-container {
   margin-top: 10px;
 }

 .top-search-clinic-container {
   padding: 15px;
 }

 .lastmin-text {
   padding: 6px 8px;
   border-radius: 25px;
   background-color: #6B43B5;
   color: white;
   font-size: 9.64px;
 }

 .provider-clinic-card-font {
   font-size: 14px;
 }

 .clinic-card-font {
   font-size: 9.64px;
 }

 ._mubbvpq {
   overflow: auto;
   -webkit-box-shadow: 0px 0px 13px 5px rgba(119, 119, 119, 0.48);
   -moz-box-shadow: 0px 0px 13px 5px rgba(119, 119, 119, 0.48);
   box-shadow: 0px 0px 13px 5px rgba(119, 119, 119, 0.2);
   margin-top: 25px;
   border-radius: 8px;
 }

 .appointment-card-button {
   background-color: #1376f8;
   width: 125px;
   color: white;
   margin-right: 25px;
 }

 .score-review-number {
   font-size: 40px;
   line-height: 2;
 }

 .score-review-number-center {
   font-size: 58px;
   line-height: 2.3;
 }

 .score-review-number-center-fix-number {
   font-size: 28px;
   line-height: 2.3;
 }

 .score-review-card {
   margin-top: -5px;
 }

 .rate-center-card {
   font-size: 30px !important;
 }

 .review-count {
   color: #CFCFCF;
   font-size: 10px;
   margin-bottom: 50px;
 }

 .review-count-center {
   color: #CFCFCF;
   font-size: 12px;
   margin-bottom: 50px;
 }

 .success-message-appointment {
   text-align: center;
   margin: 15px 5px;
 }

 .header-container {
   height: auto;
   align-items: center;
   padding: 25px;
 }

 .header-button {
   height: 54px;
   min-width: 140px;
   background-color: #6b43b5;
   border-radius: 40px;
   margin-left: 20px;
   color: #fff;
 }

 .form-register-modal {
   margin: 0px !important;
   padding: 0px !important;
 }

 .footer-home {
   margin-top: 50px;
   /* Default margin */

   @media (min-width: 900px) {
     margin-top: 100px;
     /* Adjusted margin for width more than 900px */
   }

   @media (min-width: 1200px) {
     margin-top: 150px;
     /* Adjusted margin for width more than 1200px */
   }
 }

 .start-self-algin {
   align-items: center;
   padding-top: 1px;
   display: flex;
 }

 .image-row-box {
   border-radius: 4px !important;
   height: 95px;
   width: -webkit-fill-available;
 }

 .column-image-container {
   display: flex;
   flex-direction: column;
   height: 100%;
   justify-content: space-between;
 }


 .overlay-image-watch-more {
   position: relative;
 }

 .overlay-image-watch-more::before {
   content: "";
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   font-weight: bold;
   color: white;
   text-align: center;
   padding: 10px;
   background-color: rgba(0, 0, 0, 0.4);
   border-radius: 4px !important;
   /* dark gray overlay */
 }

 .overlay-last.overlay-image-watch-more::before {
   content: "+ Watch more";

 }

 .app-type-selector {
   min-width: 100px;
   border-radius: 50px !important;
   background-color: white !important;
 }

 .ant-select-selection.ant-select-selection--single:active {
   border-radius: 0px;
   box-shadow: none;
 }

 .ant-select-selection.ant-select-selection--single:focus {
   border-radius: 0px;
   box-shadow: none;
 }

 .leaflet-popup-content-wrapper {
   border-radius: 4px;
 }

 .card-rate-leaflet {
   color: #fadb14;
   font-size: 14px;

 }

 .rate-parent .ant-rate-disabled .ant-rate-star:not(:last-child) {
   margin: 2px !important;
 }

 .leaflet-popup-close-button {
   display: none;
 }

 .leaflet-popup-content {
   margin: 10px 20px;
   line-height: 1.3;
   font-size: 13px;
   font-size: 1.08333em;
   min-height: 1px;
   width: fit-content !important;
 }

 .right-clinic-card {
   padding-right: 20px;
   line-height: 2;

   display: flex;
   flex-direction: column;
   justify-content: space-between;
 }

 .pl3 {
   padding-left: 3px !important;
 }

 .provider-card-discovery {
   cursor: pointer;
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
   margin-bottom: 30px;
 }

 .sticky-appointment-card {
   min-height: 600px;
   position: -webkit-sticky;
   position: sticky;
   top: 20px;
   /* Adjust this based on your needs */
   margin-bottom: 15px;
 }

 .sticky-appointment-card-provider {
   min-height: 840px !important;
 }

 /* 
 tr:has(.non-display) {
   visibility: hidden !important;
   text-align: center;
 }

 .non-display {
   height: 0px !important;
   padding: 0px !important;
 }

 .ant-picker-content:has(.custom-date) {
   height: auto !important;
 }

 .ant-picker-content:has(.non-display) {
   height: auto !important;
 }

 td:has(.non-display) {
   padding: 0px !important;
   margin: 0px !important;
   visibility: hidden !important;
 }

 tr:has(.custom-date) {
   display: table-row !important;
   visibility: visible !important;
   height: 60px;
   text-align: center;
 }

 td:has(.custom-date.selected) {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
 }

 td:has(.custom-date.today) {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
 }

 .custom-date.selected {
   background-color: #6B43B5 !important;
   border: 2px solid #6B43B5;
   color: white !important;
   border-radius: 50%;
   width: 35px;
   height: 35px;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .custom-date.today {
   width: 35px;
   height: 35px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   background-color: rgba(147, 120, 217, 0.2);
 }


 .ant-picker-content td {
   height: 5px !important;
 } */

 .discovery-layout {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   min-height: 100vh;
 }

 .leaflet-map-search-result {
   min-height: 800px !important;
   width: 100%;
   height: 100%;
 }

 .clanader-weeks-holderS {
   margin-top: 15px;
   display: flex;
 }

 .cal-1to14 {
   margin-right: 15px;
   cursor: pointer;
 }

 .cal-15to30 {

   cursor: pointer;
 }

 .x1-cal {
   margin-top: -35px;
 }

 .p-bottom-2 {
   padding-bottom: 2px !important;
 }

 .provider-page-name {
   font-size: 40px;
   font-weight: 700;
 }

 .provider-page-speciality {
   font-size: 32px;
   font-weight: 300;
 }

 .provider-page-rating {
   display: flex;
   align-items: center;
   color: #838383;
   font-weight: 400;
   font-size: 24px;
 }

 .provider-right-profile {
   line-height: 2;
   margin-left: 35px;
 }

 .cert-item-proivder {
   font-weight: bold;
   font-size: 20px;
   padding-left: 15px;
 }

 .provider-page-certificate {
   margin-top: 15%;
   display: flex;
   align-items: center;
 }

 .provider-cert-info {
   font-weight: 400;
   font-size: 16px;
   line-height: 30px;
 }

 .provider-edjucation-part {
   margin-left: 50px;
   margin-top: 15%;
 }

 .provider-part-title {
   margin-bottom: 1%;
   font-weight: 600;
   font-size: 24px;
   line-height: 36px;
   margin-top: 7%;
 }

 .provider-part-sub-title {
   margin-bottom: 4%;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   color: #838383;

 }

 .provider-about {
   font-weight: 400;
   font-size: 20px;
   line-height: 32px;
   display: flex;
   align-items: center;
   text-align: justify;
   color: #939393;

 }

 .provider-clinic-container {
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
   width: 100%;
   padding-left: 10px;
   height: 100%;
   min-height: 50px;
   padding: 45px;
 }

 .provider-office-name {
   font-weight: 600;
   font-size: 24px;
 }

 #myUniqueCarousel .alice-carousel__prev-btn,
 #myUniqueCarousel .alice-carousel__next-btn {
   background-color: transparent !important;
   /* Remove background color */
   color: #6B43B5 !important;
   min-width: 30px;
   min-height: 30px;
 }

 #myUniqueCarousel .alice-carousel__prev-btn-item,
 #myUniqueCarousel .alice-carousel__next-btn-item {
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 20px;
   line-height: 30px;
 }

 /* Optional: hide the buttons when the mouse is not over the carousel */
 #myUniqueCarousel .alice-carousel__prev-btn,
 #myUniqueCarousel .alice-carousel__next-btn {
   visibility: visible;
   font-size: 24px;
   /* Increase the font size of the button icon */
   line-height: 50px;
   /* Match the line height with the new button height */
 }

 #myUniqueCarousel:hover .alice-carousel__prev-btn,
 #myUniqueCarousel:hover .alice-carousel__next-btn {
   visibility: visible;
 }

 #myUniqueCarousel .item {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
 }

 #myUniqueCarousel .item img {
   max-width: 100%;
   display: block;
 }


 .provider-office-image-courseal {

   width: 306px;
   height: 180px;
   border-radius: 4px;
 }

 .review-card-center {

   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
   /* Your box-shadow */
 }



 .alice-carousel__wrapper {

   padding: 5px !important;
 }

 .ant-checkbox-checked .ant-checkbox-inner {
   background-color: #6B43B5 !important;
   border-color: #6B43B5 !important;
 }

 .ant-rate {
   .ant-rate-star {
     transition: none;
   }
 }
